import consumer from "./consumer"

consumer.subscriptions.create("NotificationChannel", {

    connected: function() {
        
    },
    disconnected: function() {},

    received: function(data) {
        $("#notifications-dropdown").replaceWith(data.dropdown);
        new Audio('/audio/notification.mp3').play();

        toastr.info("", data.message, {
            "timeOut": "5000",
            "extendedTImeout": "500",
            "closeButton": true,
            onclick: function() {
                $.ajax({
                    type: "GET",
                    url: data.url + ".js"
                });
            }
        });

    }
});