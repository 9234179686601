import Mousetrap from "mousetrap"

// Global Search
Mousetrap.bind('/', function() {
  initSearch();
}, 'keyup');
Mousetrap.bind('c', function() {
  newComment();
}, 'keyup');
Mousetrap.bind('p', function() {
  $(".btn-print").click();
}, 'keyup');
Mousetrap.bind('s', function() {
  window.location.replace("/home/scan");
}, 'keyup');
Mousetrap.bind('a', function() {
  document.getElementById("catalog_button").click();
}, 'keyup');
Mousetrap.bind('d', function() {
  $.ajax({
      type: "GET",
      url: "/distance/popup" + ".js"
  });
}, 'keyup');
Mousetrap.bind('t', function() {
  window.getelementb
  var newTaskButton = document.getElementById("newTask")
  if(document.body.contains(newTaskButton) ){
    newTaskButton.click()
  } else {
      $.ajax({
          type: "GET",
          url: "/tasks/new" + ".js"
      });
  }

}, 'keyup');

Mousetrap.bind('g d', function() {
  window.location.replace("/");
}, 'keyup');
Mousetrap.bind('g m', function() {
  window.location.replace("/map/index");
}, 'keyup');
Mousetrap.bind('g c', function() {
  window.location.replace("/calendar/index");
}, 'keyup');
