require('datatables.net-bs4')(window, $)



$( document ).on('turbo:load', function() {
  $("#dataTable").dataTable({
    responsive: false,
    iDisplayLength: 25,
    aaSorting: [[0, 'desc']],
    bDestroy: true,
    bLengthChange: true
  });
});
