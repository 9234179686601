import consumer from "./consumer"

$(document).on("turbo:load", function() {
  if(document.body.contains(document.getElementById("map-index"))){
    consumer.subscriptions.create("MarkerChannel", {
    
      connected: function() {
        return console.log("Marker WS Connected");
      },
      disconnected: function() {},
      received: function(data) {
        console.log(data["action"] + " " + data["relatedType"] + "-Marker" + ": " + data["id"] + " " + data["title"] + " " + " " + data["location"] + " " + " Icon: " + data["icon"] + " Date: " + data["eventDate"] + " woStatus: " +  data["woStatus"] + " woId: " + data["woId"] );

        switch(data["action"]) {
          case "create":
            createMarker(data);
          break;
          case "update":
            updateMarker(data);
          break;
          case "delete":
            deleteMarker(data);
        }
      }
    });
  }
});