import selectize from "selectize"
import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "select" ]
	static values = {
		create: { type: Boolean, default: true }
  }

  connect() {
    var type  = this.data.get("type")
    var placeholder  = this.data.get("placeholder")
    var select = this.selectTarget
    if (type == "basic"){
      this.basic(select, placeholder);
    } else if (type == "customer") {
      this.customer(select);
    }
  }


  basic(select, placeholder){
		var create = this.createValue 
    if (placeholder == null){
      var holder = "--- Select ---"
    } else {
      var holder = placeholder
    }
    $(select).selectize({
      heigh: 100,
      create: create,
      placeholder: holder
    });
  }
  
  customer(select){
    $(select).selectize({
      valueField: 'id',
      labelField: 'name',
      searchField: 'name',
      options: [],
      create: false,
      load: function(query, callback) {
        if (!query.length) return callback();
        $.ajax({
          url: '/clients/search',
          type: 'GET',
          dataType: 'json',
          data: {
            name: query,
          },
          error: function() {
            callback();
          },
          success: function(res) {
            callback(res);
          }
        });
      }
    });

  }

  disconnect (){
    var select = $(this.selectTarget)
    select[0].selectize.destroy();
  }

}
