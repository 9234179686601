import Dropzone from "dropzone"
import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "message" ]

  connect() {

    const documentable_type  = this.data.get("type")
    const documentable_id  = this.data.get("id")
    const shared  = this.data.get("shared")

    console.log(documentable_type)
    console.log(documentable_id)
    console.log(shared)


    Dropzone.options.documentDropzone = {
      paramName: "file", // The name that will be used to transfer the file
      maxFilesize: 600, // MB
      parallelUploads: 1,
      maxFiles: 10,
      dictDefaultMessage: 'Drop files or click here to upload',
      params: {
      documentable_type: documentable_type,
      documentable_id: documentable_id,
      shared: shared
    }
    
    };
    
    $(function() {
      var documentDropzone;
      mediaDropzone = new Dropzone("#document-dropzone");
      return mediaDropzone.on("success", function(file, responseText) {
        var imageUrl;
        imageUrl = responseText.file.url;
      });
    });



  }
}
