import consumer from "./consumer"

$(document).on("turbo:load", function() {
  
  if(document.body.contains(document.getElementById("calendar"))){
    return consumer.subscriptions.create("CalendarChannel", {

      connected: function() {
        return console.log("Calendar WS Connected");
      },
      disconnected: function() {},
      received: function(data) {
        var updatedBy = data["updated_by"]
        var eventId = data["id"]
        if (updatedBy != currentUser) {
          console.log("Calendar Updated-" + updatedBy);
          // return calendar.refetchEvents();
            refreshEvent(eventId);
        } else {
          console.log(updatedBy + " + " + currentUser)
        }
      }
    });
  }
});
