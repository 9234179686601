import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["container"]

  connect() {
    window.currentUser = this.data.get("user")
    var cDate = this.data.get("date")
    var date = new Date(cDate);
    console.log(date)
    var calendarEl = this.containerTarget
    var that = this
    this.init(calendarEl, that, date)
  }

  init(calendarEl, that, date) {
    let calendar = new FullCalendar.Calendar(calendarEl, {
      // calendar options
      schedulerLicenseKey: 'GPL-My-Project-Is-Open-Source',
      plugins: [],
      themeSystem: 'bootstrap',
      editable: true,
      initialDate: date,
      selectable: true,
      slotMinTime: "04:00:00",
      slotMaxTime: "22:00:00",
      scrollTime: "08:00:00",
      slotDuration: "00:15:00",
      slotLabelInterval: "01:00:00",
      initialView: 'resourceTimeGridDay',
      events: {
        url: '/events.json',
        id: 'main'
      },
      resources: '/users?module=calendar' + '&123=1',
      resourceAreaWidth: '85px',
      customButtons: {
        routeButton: {
          text: 'route',
          click: function () {
            var date = calendar.getDate().toString();
            var currentDate = date
            turbo.visit('/calendar/route' + '?date=' + currentDate);
          }
        },
        refreshButton: {
          text: 'Refresh',
          click: function () {
            calendar.refetchEvents
          }
        },
        mapButton: {
          text: 'Map',
          click: function () {
            var date = calendar.getDate().toString();
            var currentDate = date
            turbo.visit('/map' + '?date=' + currentDate);
          }
        }

      },
      titleFormat: { // will produce something like "Tuesday, September 18, 2018"
      month: 'long',
      year: 'numeric',
      day: 'numeric',
      weekday: 'long'
      },
      headerToolbar: {
        left: 'prev,next,today',
        center: 'title',
        right: 'resourceTimeGridDay,timeGridWeek,dayGridMonth,routeButton,refreshButton,mapButton'
      },
			/*
			eventDidMount: function(info) {
				const eventEl = info.el;
				const eventData = info.event.extendedProps; // Access extended properties of the event
			
				// Construct the tooltip content dynamically using event data
				eventEl.setAttribute('data-controller', 'tooltip');
				eventEl.setAttribute('data-tooltip-content-value', `
					<div class="max-w-[240px] flex flex-col gap-1 py-2 px-1">
						<div class="font-weight-bold">${eventData.workorder_title || 'Title not specified'}</div>
						<div>${eventData.workorder_subtitle || ''}</div>
						<div class="d-flex align-items-center"><div class="pr-1"><i class="fas fa-map-marker-alt"></i></div><div>${eventData.workorder_address || 'Location not specified'}</div></div>
						<div class="d-flex align-items-center"><div class="pr-1"><i class="fas fa-clock"></i></div><div>${eventData.start_time|| 'N/A'} - ${eventData.end_time || 'N/A'}</div></div> 
					</div>
				`);
				eventEl.setAttribute('data-tooltip-html-value', true);
				eventEl.setAttribute('data-tooltip-delay-value', '600');
				eventEl.setAttribute('data-tooltip-placement-value', 'top');
			},
			*/
			

      // end calendar options

      // calendar actions
      // when event resizes
      eventResize(info) {
        that.updateEvent(info);
      },
      // when event is droped
      eventDrop(info) {
        that.updateEvent(info);
      },


      // when time or dates are selected
      select: function (info) {
        that.selectAction(info)
      },

      // when event is clicked
      eventClick(info) {
        info.jsEvent.preventDefault(); // don't let the browser navigate
        that.eventClickAction(info)
      },
    });

    calendar.render();
    window.calendar = calendar

  }


  // calendar functions

  // update event
  updateEvent(info) {
    var e = info.event
    var resourceIds = []
    window.info = info

		if (info.newResource) {
			resourceIds.push(info.oldResource._resource.id)
			resourceIds.push(info.newResource._resource.id)
		}

		if (resourceIds[1]) {
			var event_data = {
				event: {
          id: e.id,
          swap_user_id: resourceIds,
          start: e.start,
          end: e.end,
          calendar: true
				}
			};
		} else {
			var event_data = {
				event: {
          id: e.id,
          start: e.start,
          end: e.end,
          calendar: true
				}
			};
		}


    $.ajax({
      url: "/events/" + e.id,
      data: event_data,
      type: 'PATCH'
    });
  }


  // select action
  selectAction(info) {
    var start = info.start
    var end = info.end
    if (info.resource) {
			var resourceId = info.resource._resource.id;
		} else {
			var resourceId = ""
		}
    $.getScript('/events/new?start=' + start + '&end=' + end + "&resource=" + resourceId + "&calendar=true", function () {});

  }


  eventClickAction(info) {
    console.log(info);
    $.getScript("/events/" + info.event.id + "/edit?calendar=true", function () {});
  }




}
