import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["daySelect", "addMoreButton", "timeFrom", "timeTo"];

  connect() {
    this.toggleAddMoreButton();
    this.observeAddedFields();
    this.removeAnyOptionFromVisibleFieldsOnLoad();
		this.updateRemoveFieldsVisibility();  // Update visibility of remove buttons

		// Bind the checkTimeWindowValidity method to the form submit event
    const form = this.element.closest('form');
    if (form) {
      const submitButton = form.querySelector('.dispatch-info-save-form');
      if (submitButton) {
        submitButton.addEventListener('click', this.checkTimeWindowValidity.bind(this));
      }
    }
  }

  toggleAddMoreButton() {
    const selectedValue = this.daySelectTarget.value;
    if (selectedValue === "Any Day") {
      this.addMoreButtonTarget.classList.add("d-none");
    } else {
      this.addMoreButtonTarget.classList.remove("d-none");
    }
  }

  handleChange() {
    this.toggleAddMoreButton();
  }

  observeAddedFields() {
    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.addedNodes.length > 0) {
          mutation.addedNodes.forEach((node) => {
            if (node.querySelector) {
              const selectElement = node.querySelector('select[data-time-window-target="daySelect"]');
              if (selectElement) {
                this.removeAnyOption(selectElement);
              }
							
							const visibleFields = this.getVisibleFields();
              const visibleFieldsLength = visibleFields.length;
              if (visibleFieldsLength > 1) {
                const firstField = this.element.querySelector('.nested-fields:first-child select[data-time-window-target="daySelect"]');
                if (firstField) {
                  this.removeAnyOption(firstField);
                }
              }
							this.updateRemoveFieldsVisibility(); // Update visibility of remove buttons
            }
          });
        }
      });
    });

    observer.observe(this.element, { childList: true, subtree: true });
  }

  getVisibleFields() {
    return Array.from(this.element.querySelectorAll('.nested-fields')).filter(field => {
      return field.style.display !== 'none';
    });
  }

  removeAnyOption(selectElement) {
    const anyOption = selectElement.querySelector('option[value="Any Day"]');
    if (anyOption) {
      anyOption.remove();
    }
  }

	addAnyOptionToFirst() {
		const visibleFields = this.getVisibleFields();
		if (visibleFields.length > 0) {
			const firstSelect = visibleFields[0].querySelector('.dispatch-info-time-window-day');
			if (firstSelect && visibleFields.length === 1) {
				const options = Array.from(firstSelect.options);
				if (!options.some(option => option.value === "Any Day")) {
					const anyOption = new Option("Any Day", "Any Day");
					firstSelect.insertBefore(anyOption, firstSelect.firstChild);
				}
			}
		}
	}

  removeAssociation() {
    const visibleFields = this.getVisibleFields();
    const visibleFieldsLength = visibleFields.length;

    if (visibleFieldsLength === 1) {
      this.addAnyOptionToFirst();
    } else {
      this.addAnyOptionToFirst(); // Recheck the first element and add the option if needed\
    }

		this.updateRemoveFieldsVisibility(); // Update visibility of remove buttons after removal
  }

  removeAnyOptionFromVisibleFieldsOnLoad() {
    const visibleFields = this.getVisibleFields();
    if (visibleFields.length > 1) {
      visibleFields.forEach(field => {
        const selectElement = field.querySelector('select[data-time-window-target="daySelect"]');
        if (selectElement) {
          this.removeAnyOption(selectElement);
        }
      });
    }
  }

	// Update visibility of remove buttons after removal
	updateRemoveFieldsVisibility() {
    const nestedFields = this.element.querySelectorAll('.nested-fields');
    nestedFields.forEach((field, index) => {
      const removeButton = field.querySelector('.remove_fields');
      if (nestedFields.length > 1) {
        removeButton.style.display = 'inline-block'; // Show the button if there are multiple fields
      } else {
        removeButton.style.display = 'none'; // Hide the button if there's only one field
      }
    });
  }

	// Time From/To Validation
	checkTimeWindowValidity(event) {
    let isValid = true;
    const visibleFields = this.getVisibleFields();
    visibleFields.forEach(field => {
      const timeFromElement = field.querySelector('[data-time-window-target="timeFrom"]');
      const timeToElement = field.querySelector('[data-time-window-target="timeTo"]');
      const validationMessageElement = field.querySelector('.validation-message');

      const timeFrom = timeFromElement.value;
      const timeTo = timeToElement.value;

      if (timeFrom && timeTo && this.convertTo24Hour(timeFrom) >= this.convertTo24Hour(timeTo)) {
        isValid = false;
        validationMessageElement.textContent = 'Time From must be before Time To';
      } else {
        validationMessageElement.textContent = '';
      }

      timeFromElement.addEventListener('change', () => this.clearValidationMessage(validationMessageElement));
      timeToElement.addEventListener('change', () => this.clearValidationMessage(validationMessageElement));
    });

    if (!isValid) {
      event.preventDefault();
      this.enableSubmitButton();
    }
  }

  convertTo24Hour(time) {
    const [timePart, modifier] = time.split(' ');
    let [hours, minutes] = timePart.split(':');
    hours = parseInt(hours, 10);
    minutes = parseInt(minutes, 10);
    if (modifier === 'PM' && hours !== 12) {
      hours += 12;
    }
    if (modifier === 'AM' && hours === 12) {
      hours = 0;
    }
    return hours * 60 + minutes;
  }

  clearValidationMessage(element) {
    element.textContent = '';
  }

  enableSubmitButton() {
    const form = this.element.closest('form');
    if (form) {
      const submitButton = form.querySelector('.dispatch-info-save-form');
      if (submitButton) {
        submitButton.disabled = false;
        submitButton.removeAttribute('data-disable-with');
      }
    }
  }
	// End

}
