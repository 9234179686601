import consumer from "./consumer"

$(document).on("turbo:load", function() {
    if(document.body.contains(document.getElementById("conversation-index"))){
        consumer.subscriptions.create("SmsmessageChannel", {
            connected: function() {
                console.log("smsmessages channel connected")
            },
            disconnected: function() {},

            received: function(data) {

                var messageItem = $("#messageItem-" + data.id)
                var activeConversation = $("[data-behavior='messages'][data-conversation-id='" + data.conversation_id + "']");


                if (messageItem.length > 0) {
                    messageItem.replaceWith(data['message'])
                } else {
                    if ($("#conversation-body").length > 0) {
                        activeConversation.append(data['message']);
                        if (data.direction == "inbound") {
                            new Audio('/audio/sms-alert.mp3').play();
                        }
                        $(".scrollable").scrollTop($(".scrollable")[0].scrollHeight);
                    }
                }

                setTimeout(function() {
                    $.ajax({
                        type: "GET",
                        url: "/conversations/sidebar_update"
                    });
                }, 1000);



                if (activeConversation.length == 1) {
                    conversationMarkRead(data.conversation_id)
                }
                UpdateCounters();



            }

        });

    }
});