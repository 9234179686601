// making global functions and libs for backward compatibility


import jQuery from 'jquery';
window.$ = window.jQuery = jQuery;

import moment from "moment"
window.moment = moment


import selectize from "selectize"
window.selectize = selectize

import Swal from "sweetalert2"
window.Swal = Swal
window.swal = Swal

import toastr from 'toastr'
window.toastr = toastr

import printJS from 'print-js'
window.printJS = printJS

import easyAutocomplete from "easy-autocomplete"
window.easyAutocomplete = easyAutocomplete




