import Imask from "imask"
import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["field"]
  static values = { type: String }

  connect(){
    var field = this.fieldTarget
    var type = this.typeValue
    
    if (type == 'phone'){
      var format = '(000)000-0000'
    } else if (type == 'date') {
      var format = '00/00/0000'
    } else if (type == 'ssn') {
      var format = '000-00-0000'
    } else {
      var format = '(000)000-0000'
    }

    IMask(
      field, {
        mask: format
      })
  }

}
