import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    var modal = document.getElementsByClassName("modal");
    if (modal) {
      $(modal).modal('hide').remove();
    }
    Turbo.visit(window.location, { action: 'replace' })
    console.log('reload')
  }
}