import datarangepicker from "daterangepicker"
import datetimepicker from 'eonasdan-bootstrap-datetimepicker'
import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "field" ]
	static values = {
		submit: { type: Boolean, default: false }, // Render HTML Content
  }  

  connect() {
    var type  = this.data.get("type")
    const field = this.fieldTarget

    if (type == "date"){
      this.date(field);
    } else if (type == "time") {
      this.time(field);
    } else if (type == "range") {
      this.range(field);
    }
  }

  date(field) {
    $(field).datetimepicker({
      locale: 'en',
      format: 'MM/DD/YYYY',
      showClose: true,
      allowInputToggle: true
    });

		var submit = this.submitValue
		if (submit) {
			$(field).on("dp.change", function() {
				document.getElementById("dateForm").submit();
			});
		}
  }

  time(field){
    $(field).datetimepicker({
      locale: 'en',
      format: 'LT',
      showClose: true,
      allowInputToggle: true
    });
  }

  range(field){
    $(field).daterangepicker({
      timePicker: false,
      timePickerIncrement: 30,
      locale: {
          format: 'MM/DD/YYYY'
      }
    });
    
    // FIXME
    //  $('input[name="dateRange"]').on('apply.daterangepicker', function(ev, picker) {
    //  $('#dateRangeForm').submit();

		var submit = this.submitValue
		if (submit) {
			$(field).on("dp.change", function() {
				document.getElementById("dateForm").submit();
			});
		}
  }

}
