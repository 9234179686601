import { Controller } from "@hotwired/stimulus"
import tippy from 'tippy.js';

// Connects to data-controller="tooltip"
export default class extends Controller {
  static values = {
    content: String,
    placement: { type: String, default: "bottom" },
    trigger: { type: String, default: "mouseenter focus" },
    // Adding delayValue to handle the delay before showing the tooltip
    delay: { type: Number, default: 0 }, // 0 for desktop, will be changed to 2000 for touch devices
		html: { type: Boolean, default: false }, // Render HTML Content
  }  
  
  connect() {
    var el = this.element
    var content = this.contentValue
    var placement = this.placementValue
    var trigger = this.triggerValue
    var delay = this.delayValue
    var theme = "light";
		var html = this.htmlValue 

    // Initialize Tippy with the provided options
    var instance = tippy(el, {
      content: html ? this._createHTMLContent(content) : content,
      placement: placement,
      trigger: trigger,
      delay: [delay, null], // Show delay, hide delay
      theme: theme,
			allowHTML: html,
      // Handle touch-specific behavior
      touch: ['hold', 500], // Use 'hold' strategy with 500ms threshold
    });

    // Detect touch device and modify settings
    if ('ontouchstart' in window || navigator.maxTouchPoints) {
      // Change the trigger to manual to handle it ourselves
      instance.setProps({ trigger: 'manual' });
      
      let touchTimer;
      el.addEventListener('touchstart', (event) => {
        touchTimer = setTimeout(() => {
          instance.show();
        }, 1500); // 1.5 seconds delay
      });

      // Clear the timer if the touch ends
      el.addEventListener('touchend', (event) => {
        clearTimeout(touchTimer);
      });
    }
  }

	// Create HTML content
  _createHTMLContent(content) {
    const div = document.createElement('div');
    div.innerHTML = content;
    return div;
  }

}
