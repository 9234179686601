import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["checkbox", "checkboxAll"]

  toggleAll() {
    const isChecked = this.checkboxAllTarget.checked
    this.checkboxTargets.forEach((checkbox) => {
      checkbox.checked = isChecked
    })
  }
}