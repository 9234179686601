/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

window.initMap = function(...args) {
	const event = new Event("google-maps-callback", {bubbles: true, cancelable: true});
	event.args = args;
	window.dispatchEvent(event);
 }

require("@rails/ujs").start()
global.Rails = Rails;
require("@rails/activestorage").start()
require("channels")

require("chartkick")

import { Turbo } from "@hotwired/turbo-rails"
window.turbo = Turbo


import "imask"

require("channels")
import '../javascripts/index.js';



import "controllers";

import { library, dom } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'
library.add(fas, far, fab)
// Kicks off the process of finding <i> tags and replacing with <svg>
dom.watch()

import './bootstrap_custom.js'

  
require("trix")
require("@rails/actiontext")

// JS for cocoon gem, consider replacing the gem and js in the future 
// import 'cocoon-js';


document.addEventListener("turbo:load", () => {
  $('[data-toggle="tooltip"]').tooltip()
  $('[data-toggle="popover"]').popover()
  $('.toast').toast({ autohide: false })
  $('#toast').toast('show')
})
import "controllers"
