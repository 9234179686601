import { Controller } from "stimulus"

export default class extends Controller {
	static targets = ["input", "dropdown"];

	connect() {
		this.intervalTimes = this.generateIntervalTimeOptions();
		this.allTimes = this.generateTimeOptions();
		this.populateTimeOptions(this.intervalTimes);
		this.dropdownTarget.classList.add('hidden');
		this.currentIndex = -1;

		// Bind Methods
		this.boundHidePicker = this.hidePicker.bind(this);
	}

	searchTime() {
		const searchTerm = this.inputTarget.value.toLowerCase();
		//console.log("searchTime called with:", searchTerm); // Debug: check the search term

		let filteredTimes;
		if (searchTerm.includes(':')) {
			// Split the input into hours and minutes
			const [inputHours, inputMinutes] = searchTerm.split(':');
			const normalizedMinutes = inputMinutes.padStart(2, '0'); // Pad minutes with leading zero if necessary
			const normalizedSearchTerm = `${inputHours}:${normalizedMinutes}`;
			filteredTimes = this.allTimes.filter(time => time.toLowerCase().startsWith(normalizedSearchTerm));
		} else if (/^\d{2}$/.test(searchTerm)) {
			const hour = searchTerm;  // Interpret as a full hour
			const modifiedSearchTermFullHour = `${hour}:00`;  // Interpret as 'hour:00', e.g., '12:00'
			const modifiedSearchTerm1 = `${hour.slice(0, 1)}:${hour.slice(1)}0`; // Interpret as 'hour:0minutes', e.g., '3:20'
			const modifiedSearchTerm2 = `${hour.slice(0, 1)}:0${hour.slice(1)}`; // Interpret as 'hour:minutes0', e.g., '3:02'
			filteredTimes = this.allTimes.filter(time =>
					time.toLowerCase().startsWith(modifiedSearchTermFullHour) ||
					time.toLowerCase().startsWith(modifiedSearchTerm1) ||
					time.toLowerCase().startsWith(modifiedSearchTerm2)
			);
		} else if (/^\d{3}$/.test(searchTerm)) {
			// Handle three-digit inputs dynamically
			const hourOption1 = searchTerm.slice(0, 1).padStart(2, '0'); // First digit as the hour
			const minutesOption1 = searchTerm.slice(1); // Next two as minutes
			const searchTermOption1 = `${hourOption1}:${minutesOption1}`;
			
			const hourOption2 = searchTerm.slice(0, 2); // First two digits as the hour
			const minutesOption2 = `${searchTerm.slice(2)}0`; // Last digit as the tens of minutes
			const searchTermOption2 = `${hourOption2}:${minutesOption2}`;

			filteredTimes = this.allTimes.filter(time =>
					time.toLowerCase().startsWith(searchTermOption1) ||
					time.toLowerCase().startsWith(searchTermOption2)
			);
		} else if (/^\d{4}$/.test(searchTerm)) {
			const modifiedSearchTerm = searchTerm.length === 4 ? `${searchTerm.slice(0, 2)}:${searchTerm.slice(2)}` : searchTerm;
			filteredTimes = this.allTimes.filter(time => time.toLowerCase().startsWith(modifiedSearchTerm));
		} else if ((/^\d{1,2}(am|pm)$/i.test(searchTerm)) || (/^\d{1,2}(a|p)$/i.test(searchTerm))) {
			const isAm = searchTerm.toLowerCase().endsWith('am') || searchTerm.toLowerCase().endsWith('a');
			const isPm = searchTerm.toLowerCase().endsWith('pm') || searchTerm.toLowerCase().endsWith('p');

			const hour = parseInt(searchTerm.match(/^\d{1,2}/)[0], 10);  // Extract and parse the hour part
	
			// Correctly handle 12 AM and 12 PM as special cases, and convert PM times to 24-hour format
			let normalizedHour;
			if (hour === 12) {
					normalizedHour = (isAm || isPm) ? 0 : 12; // 12 AM -> 00, 12 PM -> 12
			} else {
					normalizedHour = (isAm || isPm) ? hour : hour + 12; // PM times, except for 12 PM, are increased by 12
			}
	
			const searchPrefix = hour.toString().padStart(2, '0');
	
			// Ensure the comparison is accurately checking against your time formats in `allTimes`
			filteredTimes = this.allTimes.filter(time => {
					const timeLower = time.toLowerCase();
					if (isAm) {
						return timeLower.startsWith(searchPrefix) && (isAm && ( timeLower.includes('am') || timeLower.includes('a') ));
					} else if (isPm) {
						return timeLower.startsWith(searchPrefix) && (isPm && ( timeLower.includes('pm') || timeLower.includes('p') ));
					} else {
						return timeLower.startsWith(searchPrefix);
					}
			});
		} else {
				// Handle other inputs normally
				filteredTimes = this.allTimes.filter(time => time.toLowerCase().startsWith(searchTerm));
		}

		//console.log("Filtered times:", filteredTimes); // Debug: check the filtered times
		this.populateTimeOptions(filteredTimes);

		if (filteredTimes.length > 0) {
				// Reset the current index to highlight the first option
				this.currentIndex = 0;
				this.highlightOption();
		} else {
				// Reset the current index if no options are available
				this.currentIndex = -1;
		}
	}

	handleInput(event) {
		const allowedChars = /^[0-9apmAPM:]+$/; // Regex to match allowed characters
    const inputValue = this.inputTarget.value;

		// If the input value contains any characters not in the allowed list, revert to the last valid value
    if (!allowedChars.test(inputValue) && inputValue !== '') {
				this.inputTarget.value = this.lastValidValue || '';
				return;
		}

		// Save the last valid value
		this.lastValidValue = inputValue;

    if (inputValue.length === 0) {
        // If the input is empty, show the interval times
        this.populateTimeOptions(this.intervalTimes);
    } else {
        // If there is input, call searchTime to filter and show the relevant times
        this.searchTime();
    }

    // Ensure the dropdown is shown whenever there is input activity
    if (this.dropdownTarget.classList.contains('hidden')) {
        this.showPicker();
    }
	}


	populateTimeOptions(times) {
    this.dropdownTarget.innerHTML = ''; // Clear previous options
    this.dropdownTarget.innerHTML = times.map(time => `<div style="padding: 0.5rem; cursor: pointer;" class="hover-time-picker-option" data-action="click->time-picker#selectTime">${time}</div>`).join('');
	}

	// Navigation
	navigateOptions(event) {
		switch (event.key) {
			case 'ArrowDown':
					this.currentIndex = (this.currentIndex + 1) % this.dropdownTarget.children.length;
					this.highlightOption();
					break;
			case 'ArrowUp':
					this.currentIndex = (this.currentIndex - 1 + this.dropdownTarget.children.length) % this.dropdownTarget.children.length;
					this.highlightOption();
					break;
			case 'Enter':
					this.selectHighlightedOption(event);
					event.preventDefault(); // Prevent form submission if inside a form
					break;
			case 'Tab':
				this.selectHighlightedOption(event);
				// Prevent moving to the next field immediately, to allow time selection
				if (this.currentIndex >= 0 && !this.dropdownTarget.classList.contains('hidden')) {
					event.preventDefault();
				}
				break;
		}
	}

	highlightOption() {
		// Clear previous highlights
		Array.from(this.dropdownTarget.children).forEach(option => {
				option.classList.remove('time-option-highlighted');
		});

		// Highlight the current option
		if (this.currentIndex >= 0 && this.dropdownTarget.children[this.currentIndex]) {
				this.dropdownTarget.children[this.currentIndex].classList.add('time-option-highlighted');
				this.dropdownTarget.children[this.currentIndex].scrollIntoView({ block: 'nearest' });
		}
	}

	selectHighlightedOption(event) {
    if (this.currentIndex >= 0 && this.dropdownTarget.children[this.currentIndex]) {
        const selectedOption = this.dropdownTarget.children[this.currentIndex];
				/*
				if (!(event.type === 'click' && this.dropdownTarget.contains(event.target))) {
        	this.inputTarget.value = selectedOption.innerText;
				}
				*/
				// Check if event is defined and it's not a click event inside the dropdown
        if (event && !(event.type === 'click' && this.dropdownTarget.contains(event.target))) {
          this.inputTarget.value = selectedOption.innerText;
        }
        //this.hidePicker();
				this.dropdownTarget.classList.add('hidden');
    } else {
        // Logic to handle the case when Enter is pressed without navigating through options
        // For example, selecting the first matching option based on the search
        if (this.inputTarget.value) {
            const searchTerm = this.inputTarget.value.toLowerCase().trim();
            const firstMatchingOption = this.allTimes.find(time => time.toLowerCase().startsWith(searchTerm));
            if (firstMatchingOption) {
                this.inputTarget.value = firstMatchingOption;
                //this.hidePicker();
								this.dropdownTarget.classList.add('hidden');
            }
        }
    }
	}

	showPicker() {
		this.dropdownTarget.classList.remove('hidden');
		this.populateTimeOptions(this.intervalTimes);
		// document.addEventListener('click', this.hidePicker.bind(this));
		document.addEventListener('click', this.boundHidePicker);
		this.currentIndex = -1; // Reset current index
	}

	hidePicker(event) {
		// Select the highlighted option before hiding the picker
    if (this.currentIndex >= 0 && !this.dropdownTarget.classList.contains('hidden')) {
			this.selectHighlightedOption(event);
		}

		// Hide the dropdown if the click is outside the dropdown and input
		if (event && (this.dropdownTarget.contains(event.target) || this.inputTarget.contains(event.target))) {
				return;
		}
		
		this.dropdownTarget.classList.add('hidden');
		document.removeEventListener('click', this.hidePicker.bind(this));
	}

	selectTime(event) {
			const time = event.target.innerText;
			this.inputTarget.value = time;
			this.hidePicker();
	}

	formatTime(hour, minute, interval = 1) {
		// Padding with '0' if the minute is less than 10 for formatting
		const minuteFormatted = minute < 10 ? `0${minute}` : minute;
		
		// Converting 24-hour time format to 12-hour format with AM/PM
		const hour12 = hour % 12 || 12;
		const amPm = hour < 12 ? 'AM' : 'PM';

		// Add time with padded hour
		const hourFormattedPadded = hour12 < 10 ? `0${hour12}` : hour12;
		let times = [`${hourFormattedPadded}:${minuteFormatted} ${amPm}`];
		
		// Add time with unpadded hour (if hour is less than 10 and not using interval)
		if (hour12 < 10 && interval === 1) {
				times.push(`${hour12}:${minuteFormatted} ${amPm}`);
		}
		
		return times;
	}

	generateIntervalTimeOptions() {
    const times = [];
    for (let hour = 0; hour < 24; hour++) {
        for (let minute = 0; minute < 60; minute += 15) {
            const formattedTimes = this.formatTime(hour, minute, 15); // Using 15 as the interval
            times.push(...formattedTimes);
        }
    }
    return times;
	}

	generateTimeOptions() {
    const times = [];
    for (let hour = 0; hour < 24; hour++) {
        for (let minute = 0; minute < 60; minute++) {
            const formattedTimes = this.formatTime(hour, minute); // Default interval is 1
            times.push(...formattedTimes);
        }
    }
    return times;
	}

	handleEnterKey(event) {
		if (event.key === 'Enter') {
				const searchTerm = this.inputTarget.value.toLowerCase().trim();
				if (searchTerm) {
						let selectedTime;

						if (searchTerm.includes(':')) {
							// Split the input into hours and minutes, handle AM/PM if present
							const [inputHours, inputMinutesPart] = searchTerm.split(':');
							const inputMinutes = inputMinutesPart.slice(0, 2).padStart(2, '0'); // Normalize minutes
							const amPmSuffix = inputMinutesPart.slice(2); // Get 'am', 'pm', 'a', 'p' or empty string
							const normalizedSearchTerm = `${inputHours}:${inputMinutes}${amPmSuffix}`;
							
							selectedTime = this.allTimes.find(time => {
									const timeLower = time.toLowerCase();
									return timeLower.replace(/ /g, '').startsWith(normalizedSearchTerm.replace(/ /g, ''));
							});
						// Check for 1 or 2 digits, with optional 'a', 'p', 'am', or 'pm'
						} else if (/^\d{1,2}[apm]{0,2}$/.test(searchTerm)) {
								selectedTime = this.findTimeFromHourAmPm(searchTerm);
						// Check for 3 or 4 digits, with optional 'a', 'p', 'am', or 'pm'
						} else if (/^\d{3,4}[apm]{0,2}$/.test(searchTerm)) {
								selectedTime = this.findTimeFromDigits(searchTerm);
						}

						if (selectedTime) {
								this.inputTarget.value = selectedTime;
								this.hidePicker();
						}
				}
				// Ensure that the highlighted option is selected
				this.selectHighlightedOption(event);
				event.preventDefault();
		}
	}

	findTimeFromHourAmPm(searchTerm) {
		let selectedTime;
		if (searchTerm.endsWith('am')) {
				selectedTime = this.findTime('am', searchTerm.slice(0, -2));
		} else if (searchTerm.endsWith('pm')) {
				selectedTime = this.findTime('pm', searchTerm.slice(0, -2));
		} else if (searchTerm.endsWith('a')) {
				selectedTime = this.findTime('am', searchTerm.slice(0, -1));
		} else if (searchTerm.endsWith('p')) {
				selectedTime = this.findTime('pm', searchTerm.slice(0, -1));
		} else {
				selectedTime = this.findTime('', searchTerm);
		}
		return selectedTime;
	}

	findTime(amPm, hour) {
    const hourPadded = hour.padStart(2, '0'); // Ensure hour is two digits, e.g., '08' for '8'
    const searchHourPadded = `${hourPadded}:`; // Construct a string like '08:'
    const searchHour = `${hour}:`; // Construct a string like '8:' for single-digit hours

    // Find the closest matching time, considering both padded and unpadded hours
    return this.allTimes.find(time =>
        (time.startsWith(searchHourPadded) || time.startsWith(searchHour)) && 
        (amPm ? time.toLowerCase().endsWith(amPm) : true)
    );
	}

	findTimeFromDigits(digits) {
    let amPmSuffix = '';
    // Extract AM/PM suffix and adjust 'digits' accordingly
    if (digits.endsWith('am')) {
        amPmSuffix = ' AM';
        digits = digits.slice(0, -2);  // Remove 'am'
    } else if (digits.endsWith('pm')) {
        amPmSuffix = ' PM';
        digits = digits.slice(0, -2);  // Remove 'pm'
    } else if (digits.endsWith('a')) {
        amPmSuffix = ' AM';
        digits = digits.slice(0, -1);  // Remove 'a'
    } else if (digits.endsWith('p')) {
        amPmSuffix = ' PM';
        digits = digits.slice(0, -1);  // Remove 'p'
    } else {
        // Default to AM if no 'a', 'p', 'am', or 'pm' is present, and it's a 3 or 4 digit input
        amPmSuffix = (digits.length === 3 || digits.length === 4) ? ' AM' : '';
    }

    let hour, minutes;
    if (digits.length === 3 || digits.length === 4) {
        hour = digits.length === 3 ? digits.slice(0, 1) : digits.slice(0, 2);
        minutes = digits.length === 3 ? digits.slice(1) : digits.slice(2);
    } else {
        return null; // Invalid input length
    }

    const hourPadded = hour.padStart(2, '0'); // Ensure hour is two digits
    const timeString = `${hourPadded}:${minutes}${amPmSuffix}`; // Construct a time string

    // Find the time that matches the timeString
    return this.allTimes.find(time => time === timeString);
	}

	disconnect() {
    document.removeEventListener('click', this.boundHidePicker);
  }
}
/* HTML Input
<div data-controller="time-picker" class="time-picker-main">
	<input data-action="focus->time-picker#showPicker input->time-picker#handleInput keydown->time-picker#handleEnterKey keydown->time-picker#navigateOptions" data-time-picker-target="input" type="text" placeholder="Select Time" pattern="[0-9apmAPM:]*" class="forz-input-select"><input>
	<div data-time-picker-target="dropdown" class="time-picker-dropdown">
			<!-- Time options will go here -->
	</div>
</div>
*/