window.cardFormat = function cardFormat(){
  $("input[name='transaction[amount]']").on("change keyup", function(){
     $("input[name='commit']").val("Pay $"+this.value);
  });
  $("input[name='transaction[amount]']").on("input", function(){
     this.value = this.value.replace(/[^0-9\.]/g,'');
  });

  $("#card_number_input").on("input",function cc_format() {
     this.value = this.value.replace(/[^0-9 ]/g, '');
  });
  $("input[name='transaction[cvc]']").on("input", function(){
     this.value = this.value.replace(/[^0-9]/g, '');
  });
  $("input[name='transaction[billing_zip_code]']").on("input", function(){
     this.value = this.value.replace(/[^0-9]/g, '');
  });
  $("input[name='transaction[expiration_date]']").on("input", function(){   
     //replaces anything that is not a number or /
     this.value = this.value.replace(/[^0-9\/]/g, '');
     //Valid month:
     if(this.value.length == 1){
        this.value = this.value.replace(/[2-9]/,''); //eg: 5 => ''
     }else if(this.value.length == 2){
        this.value = this.value.replace(/1[3-9]/,'1'); //eg: 16 -> 1
        this.value = this.value.replace(/00/,'0'); 
     }

     if( this.value.length == 7 && !(/\d{2}\/\d{4}/.test(this.value)) ){
        this.value = "";
     }
  });
  $("input[name='transaction[expiration_date]']").on("keyup", function(e){   
     if(this.value.length == 2 && e.keyCode != 8 && e.key != "/"){
        this.value = this.value+"/";
     }
     if(e.key == "/" && this.value.length != 3){
        //Prevent user from writing "/"
        this.value = this.value.replace(/(.?)\/$/,'$1');
     }         
  });
  $("#card_number_input").on("keyup",function cc_format(e) {
      // supports Amex, Master Card, Visa, and Discover
      // parameter 1 ccid= id of credit card number field
      // parameter 2 ctid= id of credit card type field
      var ccid = "card_number_input";
      var ctid =  "card_type";

      var ccNumString = document.getElementById(ccid).value;
      ccNumString = ccNumString.replace(/[^0-9]/g, '');
      // mc, starts with - 51 to 55
      // v, starts with - 4
      // dsc, starts with 6011, 622126-622925, 644-649, 65
      // amex, starts with 34 or 37
      var typeCheck = ccNumString.substring(0, 2);
      var cType='';
      var block1='';
      var block2='';
      var block3='';
      var block4='';
      var formatted='';

      if  (typeCheck.length==2) {
          typeCheck=parseInt(typeCheck);
          if (typeCheck >= 40 && typeCheck <= 49) {
              cType='Visa';
          }
          else if (typeCheck >= 51 && typeCheck <= 55) {
              cType='Master Card';
          }
          else if ((typeCheck >= 60 && typeCheck <= 62) || (typeCheck == 64) || (typeCheck == 65)) {
              cType='Discover';
          }
          else if (typeCheck==34 || typeCheck==37) {
              cType='American Express';
          }
          else {
              //cType='Invalid card number';
              cType='Unknown card type';

          }
      }

      // all support card types have a 4 digit firt block
      block1 = ccNumString.substring(0, 4);
      if (block1.length==4) {
          block1=block1 + ' ';
      }

      if (cType == 'Visa' || cType == 'Master Card' || cType == 'Discover') {
          // for 4X4 cards
          block2 = ccNumString.substring(4, 8);
          if (block2.length==4) {
              block2=block2 + ' ';
          }
          block3 = ccNumString.substring(8, 12);
          if (block3.length==4) {
              block3=block3 + ' ';
          }
          block4 = ccNumString.substring(12, 16);
      }
      else if (cType == 'American Express') {
          // for Amex cards
          block2 =  ccNumString.substring(4, 10);
          if (block2.length==6) {
              block2=block2 + ' ';
          }
          block3 =  ccNumString.substring(10, 15);
          block4='';
      }
      else if (cType == 'Invalid') {
          // for Amex cards
          block1 =  typeCheck;
          block2='';
          block3='';
          block4='';
      }else{
        block1 = ccNumString;
      }

      //var classname= cType == 'Invalid card number' ? "text-danger" : "text-success";
      var classname= cType == 'Unknown card type' ? "text-danger" : "text-success";
      
      formatted=block1 + block2 + block3 + block4;
      if(e.keyCode == 8) formatted= formatted.trim(); //keyCode 8 is backspace
      document.getElementById(ccid).value = formatted;
      document.getElementById(ctid).innerHTML= cType;
      document.getElementById(ctid).className = classname;
  });
}
//Highlights selected card. See css class 'row-highlight'
window.cardHighlight = function cardHighlight(){
  $("input[name='card_id']").on("click", function(){
     $(this).closest('tr').attr('class', 'row-highlight');
     $(this).closest('tr').siblings().attr('class', '');
  });
  $("input[name='amount']").on("input",function(){
     this.value = this.value.replace(/[^0-9\.]/g,'');
  });
  $("input[name='amount']").on("change keyup",function(){
     $("input[name='commit']").val("Pay $"+this.value);
  });
}
