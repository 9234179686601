import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ['calendar', 'dateTitle'];
	static outlets = ["planner-map"];

  connect() {
    this.initializeCalendar();
  }

  initializeCalendar() {
    const calendarEl = this.calendarTarget;
    let events = JSON.parse(this.data.get('events'));
    const resources = JSON.parse(this.data.get('resources'));

    events = events.map(event => {
			const routeDate = event.route_date;
      const referenceStartTime = new Date(routeDate + 'T00:00:00');
			
      const startTimeSeconds = new Date(event.start).getTime() / 1000;
      const endTimeSeconds = new Date(event.end).getTime() / 1000;

      const startDateTime = new Date(referenceStartTime);
      startDateTime.setSeconds(startTimeSeconds);

      const endDateTime = new Date(referenceStartTime);
      endDateTime.setSeconds(endTimeSeconds);

      event.start = startDateTime.toISOString();
      event.end = endDateTime.toISOString();

      return event;
    });

		// Find the earliest event date and time
    this.earliestEvent = events.reduce((earliest, event) => {
      const eventDate = new Date(event.start);
      return eventDate < earliest ? eventDate : earliest;
    }, new Date(events[0].start));

    this.scrollTime = this.earliestEvent.toTimeString().split(' ')[0];

    const calendar = new FullCalendar.Calendar(calendarEl, {
      plugins: [],
			initialView: 'resourceTimelineDay',
			initialDate: this.earliestEvent, // Set the initial date to the earliest event dat
      resources: resources,
      events: events,
      slotDuration: { minutes: 15 },
			scrollTime: this.scrollTime, // Set the scroll time to the earliest event time
      resourceAreaHeaderContent: '',
			resourceAreaWidth:  "200px",
      eventTimeFormat: {
        hour: '2-digit',
        minute: '2-digit',
        hour12: false
      },
      timeZone: 'local',
			views: {
				resourceTimeline: {
					slotLabelFormat: [
						{ hour: 'numeric', minute: '2-digit' }
					],
				},
			},
			headerToolbar: {
				left: '',
				center: '',
				right: '',
				},
				titleFormat: {
				year: 'numeric', 
				month: 'long', 
				day: 'numeric', 
				weekday: 'long'
			},
			resourceLabelContent: function(data) {
				const route_title = data.resource.title;
				const agent_id = data.resource.id;
				const route_planner_id = data.resource.extendedProps.route_planner_id;
				const route_color = data.resource.extendedProps.color;	
        const container = document.createElement('div');
				const user_avatar_html = data.resource.extendedProps.user_avatar_html;
        container.innerHTML = `
					<div>
						<a style="color: #505d69; display: block; width: 100%;" class="flex items-center font-weight-bold" data-turbo-frame="data_route_index" href="/route_planners/${route_planner_id}?agent_id=${agent_id}">
							<div style="min-height: 49px; min-width: 200px;" class="tab-hover d-flex align-items-center justify-content-between px-2 py-1">
								<div class="d-flex align-items-center">
									<span style="margin-right: 5px; display: inline-block; background: ${route_color}; width: 16px; min-width: 16px; height: 16px; min-height: 16px; border-radius: 50px;"></span>
									<div style="overflow: hidden; text-overflow: ellipsis; white-space: nowrap; max-width: 125px;" data-controller="tooltip" data-tooltip-content-value="${route_title || 'Route'}">
										${route_title || 'Route'}
									</div>
								</div>
								${user_avatar_html}
							</div>		
						</a>
					</div>
				`;
				return { domNodes: [container] };
      },
			eventContent: function(data) {
				const { bg_color, text_color, border_color } = data.event._def.extendedProps;
				const event_index = data.event._def.extendedProps.event_index;
				const event_title = data.event.title;

				const el = document.createElement('div');
        const attributes = {
          'class': 'font-weight-bold forz-box-shadow-none min-h-[30px] p-1 flex items-center justify-center'
        };

        Object.entries(attributes).forEach(([key, value]) => el.setAttribute(key, value));
				// Render custom icons for start and end events
				if (event_title.includes('<i')) {
					el.innerHTML = `
						<div class="text-center forz-planner-timeline-start-end-event-icon">
							${event_title}
						</div>
					`;
				} else {
					el.innerHTML = `
						<div class="text-center" style="overflow: hidden; text-overflow: ellipsis; white-space: nowrap;">
							<span style="background: #ffffff; color: ${bg_color}; border-radius: 8px; padding: 0 3px; display: inline-block; padding: 0 3px; min-width: 14px; line-height: 14px;">${event_index}</span> ${event_title}
						</div>
					`;
				}

        return { domNodes: [el] };
      },
			eventDidMount: function(data) {
        const eventEl = data.el;
        const { bg_color, text_color, border_color } = data.event._def.extendedProps;

				const route_planner_id = data.event._def.extendedProps.route_planner_id;
				const agent_id = data.event._def.resourceIds[0];
				const event_index = data.event._def.extendedProps.event_index;

				const event_title = data.event.title;
				const tooltip_title = data.event._def.extendedProps.tooltip_title;
				const event_start_time = data.event._def.extendedProps.event_start_time;
				const event_end_time = data.event._def.extendedProps.event_end_time;
				const event_duration = data.event._def.extendedProps.event_duration;
				const event_full_address = data.event._def.extendedProps.event_full_address;

				if (event_title.includes('<i')) {
					eventEl.classList.add("forz-planner-timeline-start-end-event");
					eventEl.classList.add("forz-planner-timeline-event");
				} else {
					eventEl.style.backgroundColor = bg_color;
					eventEl.style.color = text_color;
					eventEl.style.borderWidth = "1px";
					eventEl.style.borderColor = border_color;
					eventEl.classList.add("forz-planner-timeline-event");
				}
				
				// Open Route Details
				eventEl.href = `/route_planners/${route_planner_id}?agent_id=${agent_id}`;
				eventEl.setAttribute('data-turbo-frame', 'data_route_index');

				// Highlight Event on Map (Planner Map Outlet)
				eventEl.addEventListener('click', this.highlightEventOnMap.bind(this, `${agent_id}-${event_index}`));

				// Add tooltip data attributes
				eventEl.setAttribute('data-controller', 'tooltip');
				if (event_title.includes('<i')) {
					eventEl.setAttribute('data-tooltip-content-value', `
						<div class="max-w-[240px] flex flex-col gap-1 py-2 px-1">
							<div class="font-weight-bold">${tooltip_title}</div>
							<div>${event_full_address}</div>
							<div class="d-flex align-items-center"><div class="pr-1"><i class="fas fa-clock"></i></div><div>${event_start_time}</div></div> 
						</div>
					`);
				} else {
					eventEl.setAttribute('data-tooltip-content-value', `
						<div class="max-w-[240px] flex flex-col gap-1 py-2 px-1">
							<div class="font-weight-bold">${event_title}</div>
							<div>${event_full_address}</div>
							<div class="d-flex align-items-center"><div class="pr-1"><i class="fas fa-clock"></i></div><div>${event_start_time} - ${event_end_time} (${event_duration})</div></div> 
						</div>
					`);
				}
				eventEl.setAttribute('data-tooltip-html-value', true);

      }.bind(this)
    });

    calendar.render();

		// For External Buttons and Title
		this.calendar = calendar;
		this.updateDateTitle(calendar.currentData.currentDate);
  }

	// Highlight Event on Map (Planner Map Outlet)
	highlightEventOnMap(eventId) {
    if (this.hasPlannerMapOutlet) {
      this.plannerMapOutlet.highlightEvent(eventId);
    }
  }

	// External Buttons
	resetView() {
		this.calendar.gotoDate(this.earliestEvent);
    this.calendar.setOption('scrollTime', this.scrollTime);
		this.updateDateTitle(this.calendar.getDate());

		// Reset the map view (Planner Map Outlet)
    if (this.hasPlannerMapOutlet) {
      this.plannerMapOutlet.resetMapView();
    }
	}
	prev() {
		this.calendar.prev();
		this.updateDateTitle(this.calendar.getDate());
	}
	next() {
		this.calendar.next();
		this.updateDateTitle(this.calendar.getDate());
	}

	// Display Title in External Div
	updateDateTitle(date) {
    const options = { year: 'numeric', month: 'long', day: 'numeric', weekday: 'long' };
    const formattedDate = new Date(date).toLocaleDateString(undefined, options);
    this.dateTitleTarget.textContent = formattedDate;
  }

}