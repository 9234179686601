import { Controller } from "stimulus";
import mapboxgl from '!mapbox-gl';

// Connects to data-controller="planner-map"
export default class extends Controller {
	static targets = ["map", "table"];
	static values = { 
		data: Object,
		routes: Array,
		pendingMarkers: Array
	};

	connect() {
		const jsonData = this.dataValue;
		const routesInfo = this.routesValue;
		const pendingMarkers = this.pendingMarkersValue;
		const map_div = this.mapTarget;

		this.mapMarkers = {};
		this.currentHighlightedMarker = null; // Track the currently highlighted marker
		this.initializeMap(map_div, jsonData, routesInfo);
		this.addPendingMarkers(pendingMarkers);
	}

	async initializeMap(map_div, jsonData, routeInfo) {
		mapboxgl.accessToken = 'pk.eyJ1IjoiZm9yeiIsImEiOiJjbG1vYzE3ZTMwMmVyMmxrN2c1aHp4dXJ0In0.tri617Ld1NWcNmmRJiUl2w'; // Replace with your actual Mapbox access token
		const apiKey = '3551472cb3be4bcc858006ea6fd6650a'; // Replace with your actual Routing API key
		const map = new mapboxgl.Map({
			container: map_div,
			style: 'mapbox://styles/mapbox/streets-v11',
			zoom: 1
		});

		this.map = map; // Save the map instance

		this.addPendingMarkers(this.pendingMarkersValue);

		const routePlans = jsonData.features;

		map.on('load', async () => {
			await Promise.all(routePlans.map(async (agentPlan, index) => {
				const agentId = agentPlan.properties.agent_id;
				const agentRouteInfo = routeInfo.find(route => route.id == agentId);

				const waypoints = agentPlan.properties.waypoints.map(waypoint => waypoint.location);
				const travelMode = agentPlan.properties.mode;
				const color = agentRouteInfo.color;
				const waypointColor = agentRouteInfo.color;

				const routeData = await this.generateRoute(waypoints, travelMode, apiKey);

				const routeLayerId = `agent-route-${index}`;
				
				agentPlan.routeLayer = routeLayerId;

				// Add route layer with adjusted offset and opacity
				map.addSource(routeLayerId, {
					type: 'geojson',
					data: routeData
				});

				map.addLayer({
					'id': routeLayerId,
					'type': 'line',
					'source': routeLayerId,
					'layout': {
						'line-cap': "round",
						'line-join': "round"
					},
					'paint': {
						'line-color': color,
						'line-width': 2,
						'line-offset': index * 4, // Adjust offset to horizontally separate overlapping lines
						'line-opacity': 1 // Adjust opacity for visibility of underlying layers
					}
				});

				// Add custom markers for waypoints and actions within waypoints
				agentPlan.properties.waypoints.forEach((waypoint, waypointIndex) => {
					waypoint.actions.forEach((action, actionIndex) => {
							const coord = waypoint.location;
							const el = document.createElement('div');
							el.className = 'forz-routes-map-marker';
							if (actionIndex === 0 && waypointIndex === 0) {
								el.innerHTML = `<div style="background: #ffffff; color: #000000; border: 2px solid ${waypointColor};"><i class="fas fa-home"></i></div>`;	
							} else if (actionIndex === waypoint.actions.length - 1 && waypointIndex === waypoints.length - 1) {
								el.innerHTML = `<div style="background: #ffffff; color: #000000; border: 2px solid ${waypointColor};"><i class="fas fa-flag"></i></div>`;
							} else {
								el.innerHTML = `<div style="background: ${waypointColor}; color: #ffffff;">${action.index}</div>`;
							}

							const marker = new mapboxgl.Marker(el)
									.setLngLat(coord)
									.addTo(map);

							// Use a unique identifier for each marker
							const markerId = `${agentId}-${action.index}`;
							this.mapMarkers[markerId] = marker;
					});
				});

				// Center the map based on the waypoints
				const bounds = new mapboxgl.LngLatBounds();
				waypoints.forEach(point => bounds.extend(point));
				this.mapBounds = bounds; // Save bounds for resetting the view
				map.fitBounds(bounds, { padding: 50, animate: false });
			}));
		});
	}

	async generateRoute(points, mode, apiKey) {
		const waypoints = points.map(position => position[1] + ',' + position[0]).join('|');
		const url = `https://api.geoapify.com/v1/routing?waypoints=${waypoints}&mode=${mode}&apiKey=${apiKey}`;

		const response = await fetch(url);
		const data = await response.json();

		return data.features[0]; // Assuming the first feature contains the route geometry
	}

	// Highlight Event on Map (Routes Timeline Outlet)
	highlightEvent(eventId) {
		if (this.currentHighlightedMarker) {
      // Reset the style of the previously highlighted marker
      this.currentHighlightedMarker.getElement().style.border = '';
      this.currentHighlightedMarker.getElement().style.boxShadow = '';
			this.currentHighlightedMarker.getElement().style.zIndex = 'auto';
    }

    const marker = this.mapMarkers[eventId];

    if (marker) {
      marker.getElement().style.border = '4px solid #ffffff'; 
			marker.getElement().style.boxShadow = '0 0 0 2px #0000ff';
			marker.getElement().style.zIndex = '2';
      const lngLat = marker.getLngLat();
      this.map.flyTo({ center: lngLat, zoom: 17 });

			// Update the currently highlighted marker
      this.currentHighlightedMarker = marker;
    }
  }

	addPendingMarkers(pendingMarkers) {
		const addMarkers = () => {
			try {
				pendingMarkers.forEach(marker => {
					const el = document.createElement('div');
					el.className = 'forz-pending-marker';
					el.innerHTML = `<div style="background: #ffffff; color: red; border: 1px solid red;"><i class="fas fa-map-marker-alt"></i></div>`;
	
					// The location is already in the correct format [lng, lat]
					const coordinates = marker.location;
	
					new mapboxgl.Marker(el)
						.setLngLat(coordinates)
						.setPopup(new mapboxgl.Popup({ offset: 25 })
							.setHTML(`
								<div style="font-size: 16px; font-weight: bold;">WO-${marker.number}</div>
								<div style="padding-top: 5px; font-size: 13.6px;">
									<div><i class="fas fa-map-marker-alt"></i> ${marker.site}</div>
									<div style="padding-top: 10px;"><i class="fas fa-user"></i> ${marker.user}</div>
									<div><i class="fas fa-building"></i> ${marker.organization}</div>
								</div>
								<div class="border-top text-muted" style="margin-top: 10px; padding-top: 10px; font-size: 12px; line-height: 1.5;">
									<div>Skills: ${marker.skills}</div>
									<div>Duration: ${marker.duration}</div>
									<div>Time Window: ${marker.time_window}</div>
									<div>Schedule Date: ${marker.required_schedule_date}</div>
									<div>Due Date: ${marker.due_date}</div>
								</div>
							`))
						.addTo(this.map);
				});
			} catch (error) {
				console.error('Error adding pending markers:', error);
			}
		};
	
		if (this.map.loaded()) {
			addMarkers();
		} else {
			this.map.on('load', addMarkers);
		}
	}

	resetMapView() {
    if (this.mapBounds) {
      this.map.fitBounds(this.mapBounds, { padding: 50 });

			if (this.currentHighlightedMarker) {
        // Reset the style of the currently highlighted marker
        this.currentHighlightedMarker.getElement().style.border = '';
        this.currentHighlightedMarker.getElement().style.boxShadow = '';
				this.currentHighlightedMarker.getElement().style.zIndex = 'auto';
        this.currentHighlightedMarker = null;
      }
    }
  }
}
