import { Controller } from "stimulus"

// Connects to data-controller="geocomplete"
export default class extends Controller {
	static targets = [ "latitude", "longitude", "city", "state", "zip", "map", "street", "name" ]
	static values = {
		singlefield: { type: Boolean, default: false }
  }

  connect() {
    if (typeof(google) !== 'undefined') {
      this.initMap();
      console.log("connected to initMap")
    } else {
      console.error('Google Maps is not defined');
    }
  }
  
	initMap() {
		if (this.hasMapTarget) {
			this.map = new google.maps.Map(this.mapTarget, {
				center: new google.maps.LatLng(this.data.get("latitude") || 39.5, this.data.get("longitude") || -98.35),
				zoom: (this.data.get("latitude") == null ? 4 : 16),
			});
			
			this.autocomplete = new google.maps.places.Autocomplete(this.streetTarget)
			this.autocomplete.bindTo('bounds', this.map)
			this.autocomplete.setFields(['address_components', 'geometry', 'icon', 'name'])
			this.autocomplete.addListener('place_changed', this.placeChanged.bind(this))

			this.marker = new google.maps.Marker({
				map: this.map,
				position: new google.maps.LatLng(this.data.get("latitude"), this.data.get("longitude")),
				anchorPoint: new google.maps.Point(0, -29),
			});
		}
  }

  placeChanged() {
		let place = this.autocomplete.getPlace()
		if (!place.geometry) {
			window.alert("No details available for input: '" + place.name + "'");
			return;
		}
		if (place.geometry.viewport) {
			this.map.fitBounds(place.geometry.viewport);
		} else {
			this.map.setCenter(place.geometry.location);
			this.map.setZoom(17);
		}
		this.marker.setPosition(place.geometry.location);
		this.marker.setVisible(true);

		const addressComponents = place.address_components
		const findAddressComponent = (addressComponents, type) => {
			return addressComponents.find(component => component.types.includes(type));
		};
		
		// Check if the object has latitude and longitude targets
		if (this.hasLatitudeTarget && this.hasLongitudeTarget) {
			const { lng, lat } = place.geometry.location;
			this.longitudeTarget.value = lng();
			this.latitudeTarget.value = lat();
		}

		// A small utility function to get component details
		const getAddressComponent = (components, type) => {
			const component = findAddressComponent(components, type);
			return component ? component.long_name : '';
		};
		
		// Get the necessary address components
		const streetNumber = getAddressComponent(place.address_components, 'street_number');
		const route = getAddressComponent(place.address_components, 'route');
		const city = getAddressComponent(place.address_components, 'locality') 
    || getAddressComponent(place.address_components, 'sublocality') 
    || getAddressComponent(place.address_components, 'sublocality_level_1');
		const stateComponent = findAddressComponent(place.address_components, 'administrative_area_level_1');
		const state = stateComponent ? stateComponent.short_name : ''; // Use short_name for state abbreviation
		const zipCode = getAddressComponent(place.address_components, 'postal_code');

		// Build the address strings
		const streetAddress = `${streetNumber} ${route}`.trim();

		// Set the value of field target
		if (this.singlefieldValue == false) {
			this.streetTarget.value = streetAddress;
		} else {
			const streetFullAddress = `${streetNumber} ${route} ${city} ${state} ${zipCode}`.trim();
			this.streetTarget.value = streetFullAddress;
		}

		if (this.hasCityTarget) {
			this.cityTarget.value = city;
		}
		if (this.hasStateTarget) {
			this.stateTarget.value = state;
		}
		if (this.hasZipTarget) {
			this.zipTarget.value = zipCode;
		}
    if (this.hasNameTarget) {
      this.nameTarget.value = streetAddress
    }
  }
}
