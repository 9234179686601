import { Controller } from "stimulus"
import Rails from '@rails/ujs'

export default class extends Controller {
  static targets = [ "map_div" ]
  static values = {
    url: String
  }

  connect() {
    var map = this.initMap();
    this.setMarkers(map, this);
    window.map = map
  }

  initMap() {
    return new google.maps.Map(this.map_divTarget, {
      // TODO: center based on appaccount address / preferences
      center: { lat: 40.839150, lng: -74.103400 },
      zoom: 8
    });
  }

  // set markers from JSON source
  setMarkers(map, that){
      Rails.ajax({
      type: 'GET',
      url: this.urlValue,
      dataType: 'json',
      // success fetching markers
      success(response) {
        var coords = []
        var waypts = new Array()

        
        // setting each marker
        response.forEach(async function(value) {
          var pos = new google.maps.LatLng(value.location[0], value.location[1])
          coords.push(pos);
          let marker = new google.maps.Marker({
            map: map,
            position: pos,
            title: value.title,
            suppressMarkers
          });

          marker.metadata = {type: "point", id: value.id};
          // marker click
          marker.addListener('click', function() {
            that.markerClick();
          });
          
        })
        // Connect multiple markers
        var directionsService = new google.maps.DirectionsService();
        var directionsRenderer = new google.maps.DirectionsRenderer();        
        var len  = coords.length;
        // grab directions in between initial and final position
        for(var i = 1; i < len-1; i++){
          waypts.push({
            location: coords[i],
            stopover: true
          })
        }
        var request = {
          origin: coords[0],
          destination: coords[len-1],
          waypoints: waypts,
          optimizeWaypoints: false,
          travelMode: 'DRIVING',
        };
        directionsService.route(request, function(result, status) {
          if (status == 'OK') {
            directionsRenderer.setDirections(result);
          }
        });
        directionsRenderer.setMap(map);
        
      },
      // error fetching the markers
      error(response) {
        alert("Error fetching markers");
      }
    })
  }

  markerClick() {
    console.log("marker click");
  }
}
