
$( document ).on('turbo:load', function() {
  sharedJS();
  links();
});


window.pleaseWait = function pleaseWait() {
  Swal.fire({
    title: 'Please Wait...',
    allowOutsideClick: false,
    allowEscapeKey: false
  });
  Swal.showLoading();
}


window.pleaseWaitAutoClose = function pleaseWaitAutoClose(ms) {
  Swal.fire({
    title: 'Please Wait...',
    allowOutsideClick: false,
    allowEscapeKey: false
  });
  Swal.showLoading();
  setTimeout(function(){ Swal.close(); }, ms);
}



window.sharedJS = function sharedJS() {
  $('[id$=user_id], [id$=eq_select]').selectize({});

  // TODO move all to new stm controller
  // $('#datepick, #datepick2').datetimepicker({
  //   locale: 'en',
  //   format: 'MM/DD/YYYY',
  //   showClose: true,
  //   allowInputToggle: true
  // });

  // customers selectize
  $('[id$=customer_id], [id$=client_id]').selectize({
    valueField: 'id',
    labelField: 'name',
    searchField: 'name',
    options: [],
    create: false,
    load: function(query, callback) {
      if (!query.length) return callback();
      $.ajax({
        url: '/clients/search',
        type: 'GET',
        dataType: 'json',
        data: {
          name: query,
        },
        error: function() {
          callback();
        },
        success: function(res) {
          callback(res);
        }
      });
    }
  });

  $("#docSearch").keyup(function(){
    
    var input, filter, table, tr, td, i, txtValue;

    input = document.getElementById("docSearch");

    if($("#no-results").length) $("#no-results").remove();

    filter = input.value.toUpperCase();
    table = document.getElementById("docsTable");
    tr = table.getElementsByTagName("tr");

    // Loop through all table rows, and hide those who don't match the search query
    for (i = 0; i < tr.length; i++) {
      td = tr[i].getElementsByTagName("td")[0];
      if (td) {
        txtValue = td.textContent || td.innerText;
        if (txtValue.toUpperCase().indexOf(filter) > -1) {
          tr[i].style.display = "";
        } else {
          tr[i].style.display = "none";
        }
      }
    } 
    if($(tr).filter(":visible").length == 1){
      $(table).append("<tr id='no-results'><td><center>No results for '"+input.value+"'</center></td></tr>");
    }
  });

  // $('#timepick, #timepick2').datetimepicker({
  //   locale: 'en',
  //   format: 'LT',
  //   showClose: true,
  //   allowInputToggle: true
  // });

  $("#dataTable").dataTable({
    responsive: true,
    iDisplayLength: 50,
    aaSorting: [[0, 'desc']],
    bDestroy: true,
  });

  $(".data-table-10").dataTable({
    responsive: true,
    iDisplayLength: 10,
    aaSorting: [[0, 'desc']],
    bDestroy: true,
  });

  $(".dataTable-simple").dataTable({
    responsive: true,
    iDisplayLength: 100,
    aaSorting: [[0, 'desc']],
    bDestroy: true,
    "bPaginate": false,
    "bLengthChange": false,
    "bFilter": true,
    "bInfo": false,
    "bSort" : false,
    "bAutoWidth": false,
    "dom": '<"wrapper"flipt>'

  });


  return $('[data-toggle="tooltip"]').tooltip({
    trigger: "hover"
  });

};


window.links = function links() {
  $("td[data-link]").click(function() {
    var link = $(this).data("link")
    $.ajax({
    type: "GET",
    url: link + ".js"
  });
})

  $("tr[data-link]").click(function() {
    var link = $(this).data("link")
    $.ajax({
    type: "GET",
    url: link + ".js"
  });
})
};

window.phoneMask = function phoneMask(elm){
  IMask(
  elm, {
    mask: '(000)000-0000'
  });
}

window.insertParam = function insertParam(key, value)
{
    key = encodeURI(key); value = encodeURI(value);

    var kvp = document.location.search.substr(1).split('&');

    var i=kvp.length; var x; while(i--) 
    {
        x = kvp[i].split('=');

        if (x[0]==key)
        {
            x[1] = value;
            kvp[i] = x.join('=');
            break;
        }
    }

    if(i<0) {kvp[kvp.length] = [key,value].join('=');}

    //this will reload the page, it's likely better to store this until finished
    document.location.search = kvp.join('&'); 
}

$(document).on('turbolinks:before-cache', function() {
  return $('[data-selectize]').each(function() {
    return this.selectize.destroy();
  });
});