import { Controller } from "stimulus"

export default class extends Controller {
static targets = ["container"]

  connect() {
    let calendarEl = this.containerTarget;
    let calendar = new Calendar(calendarEl, {
      plugins: [ dayGridPlugin ],
      initialView: 'dayGridMonth',
      events: {
        url: '/vehicles/events',
        id: 'main'
      }
    });
    calendar.render();
  }
}
