// hello_controller.js
import { Controller } from "stimulus"
import { BrowserBarcodeReader } from '@zxing/library';


export default class extends Controller {
  static targets = [ "video", "scanButton", "title", "result", "field", "form" ]

  connect() {
    console.log("scan connect")
    this.codeReader = new BrowserBarcodeReader();
    this.initScan();
    this.fieldTarget.focus();
  }

 initScan(){
  console.log("init scan")

  this.codeReader
    .listVideoInputDevices()
    .then(videoInputDevices => {
      videoInputDevices.forEach(device =>
        console.log(`${device.label}, ${device.deviceId}`)
      );
    })
    .catch(err => console.log(err));

  }

  start(){
    this.scanButtonTarget.style = "display:none;";
    this.titleTarget.innerHTML = "<h4>"+"QR CODE SCAN"+"</h4>";
    this.formTarget.style = "display:none;"
    this.videoTarget.style = "display:block;"
    this.codeReader.decodeFromInputVideoDevice(undefined, 'video').then((result) => {
      // scanned action 
      this.resultTarget.innerHTML = "<p>" + "Searching... " + result.text + "</p>"
      this.fieldTarget.value = result.text
      this.formTarget.submit();

    }).catch((err) => {
      // scan error
      console.error(err)
      this.resultTarget.innerHTML = "<p>" + err + "</p>"
    })
  }

}
