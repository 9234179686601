import consumer from "./consumer"

$(document).on("turbo:load", function() {
  if(document.body.contains(document.getElementById("map-index"))){
    consumer.subscriptions.create("MapChannel", {
    
      connected: function() {
        return console.log("Map WS Connected");
      },
      disconnected: function() {},
      received: function(data) {
        console.log("Map Update");
      }
    });
  }
});
