import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    var id  = this.data.get("id")
    var input = document.getElementById(id)
    input.value = ""
    input.focus();
  }
}
