import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "message" ]

  connect() {
    console.log("extend controller")
  }
  
  set(){
    var newDate = this.data.get("date")
    var field = document.getElementById("extDatepick")
    field.value = newDate;
    console.log(newDate)
  }

}
