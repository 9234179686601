import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="loading"
export default class extends Controller {
  static values = {
    url: String,
    interval: { type: Number, default: 5000 },  // Default interval set to 5000 milliseconds (5 seconds)
    action: { type: String, default: 'reload' }  // Action to take when task is complete
  }

  connect() {
		console.log("HHOla")
    this.checkStatus(); // Initial check on connect
    // Set up a recurring check using the interval
    this.timer = setInterval(() => this.checkEveryInterval(), this.intervalValue);
  }

  async checkStatus() {
    try {
      const data = await this.getStatus(this.urlValue);
      console.log("Status data:", data);
      if (data.completed) {
        if (this.actionValue === 'reload') {
          console.log("Task completed, reloading page.");
					Turbo.visit(window.location, { action: 'replace' })
        } else if (this.actionValue === 'remove') {
          console.log("Task completed, removing element.");
          this.element.remove();  // Removes the controller's element from the DOM
        }
      } else {
        console.log("Task not completed yet.");
      }
    } catch (error) {
      console.error("Error while fetching status:", error);
    }
  }

  async getStatus(url) {
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    return await response.json();
  }

  disconnect() {
    clearInterval(this.timer);  // Clear the interval when the controller disconnects
  }

  checkEveryInterval() {
    this.checkStatus();  // Reuse checkStatus for interval checks
  }
}
