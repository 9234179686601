import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    url: String,
    multipleOpen: {
      type: Boolean,
      default: false
    }
  };

  connect() {
    if (typeof google !== 'undefined') this.initMap();
    this.currentOpenInfoWindow = null; // Track InfoWindow State
		this.previousClickedMarker = null; // Track the previously clicked marker
  }

  async initMap() {
    if (this._map) return; // Early return if map is already initialized

    this._map = new google.maps.Map(this.element, {
      disableDefaultUI: true,
      mapId: '90497d901aeba30b',
      zoom: 20,
			gestureHandling: 'greedy'
    });

    this._bounds = new google.maps.LatLngBounds();
    await this.fetchData(); // Make fetchData async and wait for it before adjusting bounds
    this.adjustMapBounds();

    // Set up interval to refetch markers every 60 seconds
    this.refetchInterval = setInterval(() => this.fetchData(), 60000);
  }

  disconnect() {
    // Clear the interval when the controller is disconnected
    if (this.refetchInterval) {
      clearInterval(this.refetchInterval);
    }
  }

  setMarkers(markersData) {
    // Clear existing markers
    if (this._markers) {
      this._markers.forEach(marker => marker.setMap(null));
    }

    this._markers = markersData.map((markerData) => this.addMarker(markerData));
    
    this._markers.forEach(marker => {
      marker.setMap(this._map);
    });
  }

  addMarker({lat, lng, color, glyph, url_content, marker_content, animation_marker}) {
    const position = new google.maps.LatLng(lat, lng);
    this._bounds.extend(position);

    const infoWindow = new google.maps.InfoWindow();

    let multiple_open = this.multipleOpenValue;

    // Pin Marker
    let pinMarker;
    if (glyph.type == "icon" || glyph.type == "text") {
      // Custom Pin Marker
      const icon = document.createElement("div");
      if (glyph.type == "icon") {
        icon.innerHTML = `<i class="${glyph.value}"></i>`;
      } else {
        icon.innerHTML = `<span style="font-weight: 500;">${glyph.value}</span>`
      }
      pinMarker = new google.maps.marker.PinView({
        background: color[0],
        glyph: icon,
        glyphColor: color[1]["text"],
        borderColor: color[1]["border"],
      });
    } else {
      // Default Pin Marker
      pinMarker = new google.maps.marker.PinView({});
    }
    let pinMarkerContent = pinMarker.element; 
    
    // Html Marker
    let htmlMarkerContent = this.buildHtmlMarker(glyph, color, url_content, marker_content, animation_marker);  
    
    const self = this; // Reference to the controller instance
    const mapMarker = new google.maps.marker.AdvancedMarkerElement({
      map: this._map,
      position: position,
      //title: "forz-map-advanced-marker-element",
			title: "",
      content: glyph.format == "pin" ? pinMarkerContent : htmlMarkerContent,
    });

		/*
    mapMarker.addListener('gmp-click', () => {
      if (animation_marker) {
        // Animation Marker
        this.toggleHighlight(mapMarker, color, multiple_open);
      } else {
        // Static Marker
        if (!multiple_open && self.currentOpenInfoWindow) {
          self.currentOpenInfoWindow.close(); // Close the previously open InfoWindow
        }

        // Toggle the InfoWindow open/close on marker click
        if (!mapMarker.infoWindowOpen) {
          infoWindow.setContent(this.buildInfoWindow(url_content, marker_content));

          infoWindow.open({
              anchor: mapMarker,
              map: this._map,
              shouldFocus: false,
          });
          mapMarker.infoWindowOpen = true;
          self.currentOpenInfoWindow = infoWindow; // Update the reference to the currently open InfoWindow

          // Listen for the InfoWindow's close event to update the flag
          google.maps.event.addListener(infoWindow, 'closeclick', () => {
              mapMarker.infoWindowOpen = false;
              if (self.currentOpenInfoWindow === infoWindow) {
                self.currentOpenInfoWindow = null; // Reset the reference if this InfoWindow is closed
              }
          });
        } else {
          // Close the InfoWindow if it's already open
          infoWindow.close();
          mapMarker.infoWindowOpen = false;
          if (self.currentOpenInfoWindow === infoWindow) {
            self.currentOpenInfoWindow = null; // Reset the reference if this InfoWindow is closed
          }
        }
      }
    });
		*/
		
		mapMarker.isClicked = false; // Track if the marker has been clicked to preserve the info window

    // Add event listeners for mouseover and mouseout on the marker content
		const markerElement = mapMarker.content;

		markerElement.addEventListener('mouseover', () => {
			if (self.previousClickedMarker && self.previousClickedMarker !== mapMarker) {
        self.previousClickedMarker.isClicked = false;
        self.previousClickedMarker = null;
      }

			if (!mapMarker.isClicked) { // Only open on hover if not clicked
				if (!multiple_open && self.currentOpenInfoWindow) {
					self.currentOpenInfoWindow.close(); // Close the previously open InfoWindow
				}
	
				// Open the InfoWindow on marker hover
				if (!mapMarker.infoWindowOpen) {
					infoWindow.setContent(self.buildInfoWindow(url_content, marker_content));
	
					infoWindow.open({
						anchor: mapMarker,
						map: self._map,
						shouldFocus: false,
					});
					mapMarker.infoWindowOpen = true;
					self.currentOpenInfoWindow = infoWindow; // Update the reference to the currently open InfoWindow
	
					// Listen for the InfoWindow's close event to update the flag
					google.maps.event.addListener(infoWindow, 'closeclick', () => {
						mapMarker.infoWindowOpen = false;
						if (self.currentOpenInfoWindow === infoWindow) {
							self.currentOpenInfoWindow = null; // Reset the reference if this InfoWindow is closed
						}
					});
				}
			}
		});
	
		markerElement.addEventListener('mouseout', () => {
			if (!mapMarker.isClicked) { // Only close on mouseout if not clicked
				if (mapMarker.infoWindowOpen) {
					infoWindow.close();
					mapMarker.infoWindowOpen = false;
					if (self.currentOpenInfoWindow === infoWindow) {
						self.currentOpenInfoWindow = null; // Reset the reference if this InfoWindow is closed
					}
				}
			}
		});
	
		markerElement.addEventListener('click', () => {
			mapMarker.isClicked = !mapMarker.isClicked; // Toggle the clicked state
			if (mapMarker.isClicked) {
				if (!mapMarker.infoWindowOpen) {
					infoWindow.setContent(self.buildInfoWindow(url_content, marker_content));
	
					infoWindow.open({
						anchor: mapMarker,
						map: self._map,
						shouldFocus: false,
					});
					mapMarker.infoWindowOpen = true;
					self.currentOpenInfoWindow = infoWindow; // Update the reference to the currently open InfoWindow
	
					// Listen for the InfoWindow's close event to update the flag
					google.maps.event.addListener(infoWindow, 'closeclick', () => {
						mapMarker.infoWindowOpen = false;
						if (self.currentOpenInfoWindow === infoWindow) {
							self.currentOpenInfoWindow = null; // Reset the reference if this InfoWindow is closed
						}
						mapMarker.isClicked = false; // Reset the clicked state when the info window is closed
					});
				}
				self.previousClickedMarker = mapMarker; // Update the previously clicked marker
			} else {
				if (mapMarker.infoWindowOpen) {
					infoWindow.close();
					mapMarker.infoWindowOpen = false;
					if (self.currentOpenInfoWindow === infoWindow) {
						self.currentOpenInfoWindow = null; // Reset the reference if this InfoWindow is closed
					}
					mapMarker.isClicked = false; // Reset the clicked state
				}
			}
		});
		
    return mapMarker;
  }

  buildHtmlMarker(glyph, color, url_content, marker_content, animation_marker) {
    const content = document.createElement("div");
    content.classList.add("forz-map-marker");

    if (animation_marker) {
      // Animation Marker
      if (url_content) {
        content.innerHTML = `<turbo-frame id="marker_popup" src="${url_content}" loading="lazy">Loading...</turbo-frame>`;
      } else {
        content.innerHTML = marker_content;
      }
    } else {
      // Static Marker
      let icon_content;
      if (glyph.type == "icon") {
        icon_content = `<i class="${glyph.value}"></i>`;
      } else {
        icon_content = `<span style="font-weight: 500;">${glyph.value}</span>`;
      }  

      content.innerHTML = `
      <div class="forz-map-marker-tail"></div>
      <div class="forz-map-marker-tail-bg"></div>
      <div class="forz-map-marker-content">
        <div class="forz-map-marker-icon text-center font-semibold">
          ${icon_content}
        </div>
      </div>`;  
    }

    const tailBg = content.querySelector('.forz-map-marker-tail-bg');
    if (tailBg) {
      tailBg.style.border = `8px solid ${color[1]['border']}`;
      tailBg.style.borderBottom = "none";
    }

    const contentDiv = content.querySelector('.forz-map-marker-content');
    if (contentDiv) {
      contentDiv.style.backgroundColor = color[0];
      contentDiv.style.color = color[1]["text"];
      contentDiv.style.border = `2px solid ${color[1]["border"]}`;
    }

    const tailDiv = content.querySelector('.forz-map-marker-tail');
    if (tailDiv) tailDiv.style.borderTopColor = color[1]["border"];

    const tailDivBg = content.querySelector('.forz-map-marker-tail-bg');
    if (tailDivBg) tailDivBg.style.borderTopColor = color[1]['border'];

    return content;
  }

  buildInfoWindow(url_content, marker_content) {
    const content = document.createElement("div");
    content.classList.add("forz-pin-map-marker");
    if (url_content) {
      content.innerHTML = `<turbo-frame id="marker_popup" src="${url_content}" loading="lazy">Loading...</turbo-frame>`;
    } else {
      content.innerHTML = marker_content;
    }
    return content;
  }

  // Handle Animation marker
  toggleHighlight(markerView, color, multiple_open) {
    // Remove highlight from the currently highlighted marker if multiple_open is false
    if (!multiple_open && this.currentOpenInfoWindow && this.currentOpenInfoWindow !== markerView) {
      this.removeHighlight(this.currentOpenInfoWindow); // Updated call
    }
  
    const contentDiv = markerView.content.querySelector('.forz-map-marker-content');
    const tailDiv = markerView.content.querySelector('.forz-map-marker-tail');
    const tailDivBg = markerView.content.querySelector('.forz-map-marker-tail-bg');
    const isHighlighted = markerView.content.classList.contains("forz-map-marker-highlight");

    // Store the original colors the first time a marker is highlighted
    if (!markerView.content.originalColor) {
      markerView.content.originalColor = {
          backgroundColor: color[0],
          borderTopColor: color[1]["border"],
          tailBgTopColor: color[0]
      };
    }

    if (isHighlighted) {
      markerView.content.classList.remove("forz-map-marker-highlight");
      if (tailDiv) contentDiv.style.backgroundColor = color[0];
      if (tailDiv) tailDiv.style.borderTopColor = color[1]["border"];
      if (tailDivBg) tailDivBg.style.borderTopColor = color[0];
      markerView.zIndex = null;

      if (this.currentOpenInfoWindow === markerView) {
        this.currentOpenInfoWindow = null;
      }
    } else {
      markerView.content.classList.add("forz-map-marker-highlight");
      if (tailDiv) contentDiv.style.backgroundColor = "#ffffff";
      if (tailDiv) tailDiv.style.borderTopColor = "#ffffff";
      if (tailDivBg) tailDivBg.style.borderTopColor = "#ffffff";
      markerView.zIndex = 9999999;

      this.currentOpenInfoWindow = markerView;
    }
  }

  removeHighlight(markerView) {
    // Ensure originalColor data exists and is valid
    if (markerView.content.originalColor) {
        const originalColor = markerView.content.originalColor;
        const contentDiv = markerView.content.querySelector('.forz-map-marker-content');
        const tailDiv = markerView.content.querySelector('.forz-map-marker-tail');
        const tailDivBg = markerView.content.querySelector('.forz-map-marker-tail-bg');

        // Apply the original colors back to the marker elements
        if (contentDiv) contentDiv.style.backgroundColor = originalColor.backgroundColor;
        if (tailDiv) tailDiv.style.borderTopColor = originalColor.borderTopColor;
        if (tailDivBg) tailDivBg.style.borderTopColor = originalColor.tailBgTopColor;
        
        markerView.content.classList.remove("forz-map-marker-highlight");
        markerView.zIndex = null;
    }
  }

  async fetchData() {
    try {
      const response = await fetch(this.urlValue);
      const data = await response.json();
      this.setMarkers(data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }

  adjustMapBounds() {
    this._map.fitBounds(this._bounds);
  }
}